.error-boundary__container {
  height: 100vh;
  width: 100vw;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  strong {
    cursor: pointer;
  }

  details {
    white-space: 'pre-wrap';
  }
}
